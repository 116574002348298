<script setup lang="ts">
const layoutCustomProps = useAttrs()

const preferredLanguage = useCookie('preferred_language');

preferredLanguage.value = null;
</script>

<template>
	<section class="bg-gray-50">
		<div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
			<a 
        v-if="!layoutCustomProps['no-logo']"
        href="/" 
        class="flex items-center text-2xl mb-3 font-semibold text-gray-900"
      >
        <LayoutLogo />
			</a>
			<div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div class="p-6 space-y-3.5">
          <div class="mb-4 text-gray-900 space-y-1.5">
            <h1 class="flex items-center gap-2.5 text-xl font-semibold leading-tight tracking-tight md:text-2xl">
              <slot name="title" />
            </h1>
            <p class="text-sm font-normal text-gray-600">
              <slot name="description" />
            </p>
          </div>
          <div v-if="$slots['error-message']" class="w-full p-4 bg-red-50 rounded-md">
            <div class="flex items-center">
              <NuxtIcon 
                name="i-lucide-circle-alert" 
                class="w-5 h-5 text-red-400" 
              />
              <div class="ml-3">
                <p class="text-sm text-red-700">
                  <slot name="error-message" />
                </p>
              </div>
            </div>
            <slot name="error" />
          </div>
          <NuxtAlert
            v-if="$slots['success-message']"
            variant="soft"
            color="green"
          >
            <template #description>
              <slot name="success-message" />
            </template>
          </NuxtAlert>
          <slot />
          <div 
            v-if="!!$slots['footer']"
            class="text-sm font-light text-gray-500 text-center"
          >
            <slot name="footer" />
          </div>
				</div>
			</div>
		</div>
	</section>
</template>

<style scoped>

</style>
